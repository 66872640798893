import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import HeaderFinancialPortal from '@components/FinancialPortal/Header/Header';
import { LayoutOnBoarding, SEO } from '@components/Structural';
import { makeStyles, Theme } from '@material-ui/core';
import { LayoutMobileInvestments } from '@components/Investments/Layout';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAuth } from '@hooks';
import { getSubscriptionPlans } from '@apollo';
import _ from 'lodash';
import ContainerCardPlans from '@components/RokinPlus/ContainerCardPlans/ContainerCardPlans';
import { Step2PlanCard } from '@components/Services/PersonalizedPlans/Step2PlanCard/Step2PlanCard';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { ROKIN_SQUARE_ICON } from 'images/GCP/RokinPlus';
import { ButtonText } from '@components/Reusables';
import { Add } from '@material-ui/icons';

const FinancialPortal = () => {
  useAuth({ redirectIfNotLogged: true });

  const isMobile = useMediaQuery('(max-width: 1240px)');
  const classes = useStyles();

  const [isSelect, setSelect] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [toggleUseEffect, setToggleUseEffect] = useState(false);

  useEffect(() => {
    const location = window.location.href.split('#')[1];
    switch (location) {
      case 'account':
        setSelect(0);
        break;
      case 'habits':
        setSelect(1);
        break;
      case 'calendar':
        setSelect(2);
        break;
      default:
        setSelect(0);
        break;
    }
  }, [toggleUseEffect]);

  const togglePage = () => {
    setToggleUseEffect(!toggleUseEffect);
  };

  const infoButtonHandler = () => {
    //OPEN POPUP
    setOpenInfoPopUp(!openInfoPopUp);
  };

  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);
  const [isTransactions, setTransactions] = useState<boolean>(false);

  const { subscriptionPlans } = getSubscriptionPlans();

  const plansList = _.cloneDeep(subscriptionPlans);
  plansList.sort((a, b) => a.id - b.id);

  const [isStep, setStep] = useState(2);
  const [chosenPlan, setChosenPlan] = useState([]);

  const rokinPlus = true;

  return (
    <>
      <SEO
        title="Administra tus finanzas personales - Rokin"
        description="Organiza tu plata, controla tus gastos, maneja tus deudas y potencia tu ahorro todo desde un solo lugar."
      />
      {isStep === 0 ? (
        !isMobile ? (
          <LayoutOnBoarding activeFinance>
            <HeaderFinancialPortal
              background={'#00323c'}
              className={isTransactions ? classes.backgroundColor : ''}
              setSelect={setSelect}
              isSelect={isSelect}
              infoPopup={infoButtonHandler}
              which={'rokinPlus'}
              rokinPlus
            >
              <ContainerCardPlans
                setStep={setStep}
                setChosenPlan={setChosenPlan}
                rokinPlus={rokinPlus}
              />
            </HeaderFinancialPortal>
          </LayoutOnBoarding>
        ) : (
          <LayoutMobileInvestments activeFinance>
            <HeaderFinancialPortal
              setSelect={setSelect}
              isSelect={isSelect}
              infoPopup={infoButtonHandler}
              background={'#00323c'}
              rokinPlus
            >
              <ContainerCardPlans
                setStep={setStep}
                setChosenPlan={setChosenPlan}
                rokinPlus={rokinPlus}
              />
            </HeaderFinancialPortal>
          </LayoutMobileInvestments>
        )
      ) : (
        <section>
          <div className={classes.header}>
            <div className="container">
              <div className="flex">
                <KeyboardArrowLeftIcon onClick={() => setStep(0)} />
                <div className="top">Paso 1 de 2</div>
              </div>
              <div className="title">Contratar Rokin Plus</div>
              {isMobile && <div className={classes.line} />}
              <div className="subtitle">
                ¡Ya estás al borde de ser Premium! Revisa el detalle, confirma
                tu suscripción y decide cuánto quieres pagar
              </div>
            </div>
          </div>
          <div className={classes.position}>
            <div className={classes.container}>
              <Step2PlanCard data={chosenPlan} setStep={setStep} />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default FinancialPortal;

const useStyles = makeStyles<Theme>((theme) => ({
  container: {},
  myAccountsContainer: {
    [theme.breakpoints.down(1240)]: {
      marginTop: '1rem',
      paddingTop: '1rem',
      background: '#008195',
    },
  },
  fullScreen: {
    position: 'fixed',
    width: '100%',
    // height: "150%",
    zIndex: 1000,
    margin: 0,
    backgroundColor: '#F4F4F3',
    top: 0,
    bottom: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  backgroundColor: {
    background: '#00323c',
  },
  popUpContainer: {
    zIndex: 1000,
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.8)',
    height: '100rem',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '10%',
    [theme.breakpoints.down(1240)]: {
      paddingTop: '35%',
    },
  },
  outerBorder: {
    width: '43.938rem',
    height: '21rem',
    borderRadius: '40px',
    border: '1px solid #FFFFFF',
    padding: '0.25rem',
    [theme.breakpoints.down(1240)]: {
      width: '21.063rem',
      height: '30.188rem',
    },
  },
  popUp: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '35px',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    [theme.breakpoints.down(1240)]: {
      borderRadius: '35px',
    },
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  popUpVector: {
    width: '100%',
    height: '0.125rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '2px',
    margin: '0.7rem 0 0 0',
    [theme.breakpoints.down(1240)]: {},
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '1.43',
    color: '#393E44',
    [theme.breakpoints.down(1240)]: {
      marginTop: '1rem',
    },
  },
  subtext: {
    fontFamily: 'Noto Sans, Montserrat',
    fontSize: '0.75rem',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '1.67',
    color: '#393E44',
    margin: '1.2rem 0 0 0',
  },
  buttonGotItContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(1240)]: {
      margin: '1.5rem 0 0 0',
    },
  },
  buttonGotIt: {
    margin: '1.2rem 0 0 0',
    width: '3rem',
  },
  textButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  rokipediaPopUpTitle: {
    fontFamily: 'Nunito, Montserrat',
    fontSize: '1rem',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: '#393E44',
    margin: '0 70% 0 0 ',
    [theme.breakpoints.down(1240)]: {
      margin: '0 30% 0 3% ',
    },
  },
  image: {
    margin: '1.5rem 1rem 0 0',
    [theme.breakpoints.down(1240)]: {
      margin: '0.7rem 1rem 0 0',
    },
  },
  image2: {
    margin: '0.7rem 1rem 0 0',
    [theme.breakpoints.down(1240)]: {
      margin: '-1.6rem 1rem 0 0',
    },
  },
  strongText: {
    fontWeight: 'bolder',
    color: 'black',
  },
  hiddenDisplay: {
    display: 'none',
  },
  header: {
    background: '#001e28',
    height: '19rem',
    color: 'white',
    '& .container': {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '47rem',
      margin: '0rem auto 0',
      paddingTop: '2rem',
      '& .flex': {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        '& svg': {
          width: '2rem',
          height: '2rem',
        },
      },
      '& .title': {
        textFamily: 'Montserrat',
        fontSize: '1rem',
        fontWeight: 'bold',
        marginLeft: '1rem',
      },
      '& .top': {
        fontFamily: 'Montserrat',
        fontSize: '0.875rem',
        fontWeight: 500,
        margin: '1rem 0',
      },
      '& .subtitle': {
        fontSize: '0.875rem',
        margin: '1rem 0 0 1rem',
      },
    },
  },
  line: {
    width: '90%',
    margin: '1rem auto 0',
    borderBottom: '1px solid white',
  },
}));
