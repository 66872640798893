import { getSubscriptionPlans } from '@apollo';
import { PlanCard } from '@components/Services/PersonalizedPlans/PlanCard/PlanCard';
import { LoadingContext } from '@context';
import { useMediaQuery } from '@material-ui/core';
import { getAuth } from 'firebase/auth';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from './ContainerCardPlans.styles';

interface Props {
  setStep: (v: number) => void;
  setChosenPlan: (v: never[]) => void;
  rokinPlus: boolean;
}

const ContainerCardPlans = ({ setStep, setChosenPlan, rokinPlus }: Props) => {
  const classes = useStyles();
  const { hideLoader } = useContext(LoadingContext);
  const currentUser = getAuth().currentUser;
  const isMobile = useMediaQuery('(max-width: 1300px)');

  useEffect(() => {
    hideLoader('');
  }, [currentUser]);

  const { subscriptionPlans } = getSubscriptionPlans();

  const plansList = _.cloneDeep(subscriptionPlans);
  plansList.sort((a, b) => a.id - b.id);

  const GetPlans = () => {
    return (
      <>
        {plansList.map((item, index) => {
          return (
            <div key={index} className={classes.plancard}>
              <PlanCard
                setStep={setStep}
                data={item}
                setChosenPlan={setChosenPlan}
                isOpen={true}
                landingVersion
                rokinPlus={rokinPlus}
              />
            </div>
          );
        })}
      </>
    );
  };
  return (
    <div className={classes.container}>
      <div className={classes.placardContainer}>{GetPlans()}</div>
    </div>
  );
};
export default ContainerCardPlans;
