import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  plancard: {
    width: '21.5rem',
    background: '#ffffff',
    borderRadius: 16,
    [theme.breakpoints.down(1240)]: {
      height: '31rem',
      margin: '0 auto',
    },
    '& span, div, p': {
      color: '#393e44',
    },
  },
  placardContainer: {
    display: 'flex',
    background: '#001e28',
    padding: '2.5rem 2rem',
    width: '57rem',
    height: '35rem',
    borderRadius: 32,
    gap: '2rem',
    margin: '2rem auto 0',
    justifyContent: 'center',
    [theme.breakpoints.down(1240)]: {
      padding: '0rem 0rem 8rem',
      width: '90%',
      height: 'auto',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
}));
